import React, { CSSProperties, PropsWithChildren } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './mission-hero.module.scss';

export function MissionHero(props: PropsWithChildren<{ id?: string }>) {
    return <div className={styles.missionHero} {...props} />;
}

export function MissionHeroBackground(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.missionHero__background} {...props} />;
}

export function MissionHeroBackgroundLayer(props: PropsWithChildren<{}>) {
    return <div className={styles.missionHero__backgroundLayer} {...props} />;
}

export function MissionHeroBody(props: PropsWithChildren<{}>) {
    return (
        <Grid className={styles.missionHero__body}>
            <div className={styles.missionHero__bodyContainer} {...props} />
        </Grid>
    );
}

export function MissionHeroImage(props: PropsWithChildren<{}>) {
    return <div className={styles.missionHero__image} {...props} />;
}
