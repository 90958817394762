import React from 'react';
import { Helmet } from 'react-helmet';
import { useMedia } from 'react-use';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { getOffsetTop } from 'utils/get-offset-top';
import { useLax } from 'utils/hooks/lax';

import {
    MissionHero,
    MissionHeroBackground,
    MissionHeroBackgroundLayer,
    MissionHeroBody,
} from 'components/mission-hero/mission-hero';
import {
    Step,
    StepBody,
    StepImage,
    StepLabel,
    Stepper,
    StepperItem,
    StepQuote,
    Steps,
    StepsAside,
    StepsBackground,
    StepsBackgroundLayer,
    StepsBody,
    StepsList,
    StepText,
} from 'components/steps/steps';

export default function MissionPage(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    useLax();

    const showAside = useMedia('(min-width: 1300px)', true);
    const [active, setActive] = React.useState('mission');
    const [color, setColor] = React.useState('dark' as 'light' | 'dark');

    const isLg = useMedia('(min-width: 600px)', false);

    React.useEffect(() => {
        if (!showAside) {
            return;
        }

        const record: Record<string, number> = {};
        const els = Array.from(document.querySelectorAll<HTMLButtonElement>('[data-stepper-anchor-id]'));

        for (const el of els) {
            const id = el.getAttribute('data-stepper-anchor-id');
            if (!id) {
                continue;
            }
            record[id] = getOffsetTop(document.getElementById(id));
        }

        const p = window.innerHeight / 2 + 200;
        const lightBgOffset = getOffsetTop(document.getElementById('light-bg')) - p;

        const keys = Object.keys(record).reverse();

        const onScroll = () => {
            const windowOffset = window.pageYOffset;

            if (color === 'dark' && windowOffset >= lightBgOffset) {
                setColor('light');
            } else if (color === 'light' && windowOffset < lightBgOffset) {
                setColor('dark');
            }

            for (const key of keys) {
                if (windowOffset > record[key] - p) {
                    if (active !== key) {
                        setActive(key);
                    }
                    break;
                }
            }
        };

        window.addEventListener('scroll', onScroll, { passive: true });

        return () => window.removeEventListener('scroll', onScroll);
    }, [active, color, showAside]);

    return (
        <>
            <Helmet>
                <title>Phase One Karma — Our Mission, Vision, and Values.</title>
                <meta property="og:title" content="Phase One Karma — Our Mission, Vision, and Values." />
                <meta name="description"
                      content="Phase One Karma is not just a product company — it is a tight-knit community built around certain values and vision."
                />
            </Helmet>
            <MissionHero id="mission-hero">
                <MissionHeroBody>
                    <span>We are bigger than just a product company. We are a value-driven community.</span>
                    <p>Read about what is beyond the products in Phase One Karma.</p>
                </MissionHeroBody>
                <MissionHeroBackground>
                    <MissionHeroBackgroundLayer data-lax data-lax-translate-y="0 0, vh 300">
                        <Img fluid={data.heroSky.childImageSharp.fluid} />
                    </MissionHeroBackgroundLayer>
                    <MissionHeroBackgroundLayer
                        data-lax
                        data-lax-translate-y="0 0, vh 200"
                        data-lax-translate-x="0 0, vh -100"
                    >
                        <Img fluid={data.heroClouds.childImageSharp.fluid} />
                    </MissionHeroBackgroundLayer>
                    <MissionHeroBackgroundLayer data-lax data-lax-translate-y="0 0, vh 200">
                        <Img fluid={data.heroMountains.childImageSharp.fluid} />
                    </MissionHeroBackgroundLayer>
                    <MissionHeroBackgroundLayer data-lax data-lax-translate-y="0 0, vh 100">
                        <Img fluid={data.heroCity.childImageSharp.fluid} />
                    </MissionHeroBackgroundLayer>
                    <MissionHeroBackgroundLayer>
                        <Img fluid={data.heroPeople.childImageSharp.fluid} />
                    </MissionHeroBackgroundLayer>
                    <MissionHeroBackgroundLayer
                        data-lax
                        data-lax-translate-x="0 0, vh 100"
                        data-lax-translate-y="0 0, vh -100"
                    >
                        <Img fluid={data.heroDrone.childImageSharp.fluid} />
                    </MissionHeroBackgroundLayer>
                </MissionHeroBackground>
            </MissionHero>

            <Steps>
                <StepsBackground>
                    <StepsBackgroundLayer color="haiti" style={{ height: 1350, width: '100%' }} id="dark-bg">
                        <div data-lax data-lax-translate-y_lg="0 0, vh -50">
                            <Img
                                fluid={data.divider1.childImageSharp.fluid}
                                style={{ transform: 'translateY(-45%)' }}
                            />
                        </div>
                    </StepsBackgroundLayer>
                    <StepsBackgroundLayer color="magnolia" style={{ height: 500, width: '100%' }} id="light-bg">
                        <Img fluid={data.divider2.childImageSharp.fluid} style={{ transform: 'translateY(-99%)' }} />
                    </StepsBackgroundLayer>
                    <StepsBackgroundLayer
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundImage: `url(${require('../img/circles.svg')}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '-100px 300px',
                            backgroundSize: '2426px 2437px',
                        }}
                    />
                </StepsBackground>

                <StepsBody>
                    <StepsAside>
                        <Stepper>
                            <StepperItem color={color} anchorId="mission" active={active === 'mission'}>
                                mission
                            </StepperItem>
                            <StepperItem color={color} anchorId="vision" active={active === 'vision'}>
                                vision
                            </StepperItem>
                            <StepperItem color={color} anchorId="values" active={active === 'values'}>
                                values
                            </StepperItem>
                            <StepperItem color={color} anchorId="karma" active={active === 'karma'}>
                                karma
                            </StepperItem>
                        </Stepper>
                    </StepsAside>
                    <StepsList>
                        <Step color="dark" style={{ marginBottom: isLg ? 400 : 200 }}>
                            <StepBody>
                                <StepLabel withPadding id="mission">
                                    01 <em>mission</em>
                                </StepLabel>
                                <StepQuote>
                                    to create a meaningful impact through developing state{'\u2011'}of{'\u2011'}the
                                    {'\u2011'}art software products
                                </StepQuote>
                            </StepBody>
                        </Step>
                        <Step color="dark" style={{ marginBottom: isLg ? 730 : 250 }}>
                            <StepBody>
                                <StepLabel withPadding id="vision">
                                    02
                                    <em>vision</em>
                                </StepLabel>
                                <StepQuote>
                                    to improve the quality of people's lives by freeing them from unnecessary manual
                                    tasks.
                                </StepQuote>
                            </StepBody>
                        </Step>
                        <Step color="light" style={{ marginBottom: 140 }}>
                            <StepBody>
                                <StepImage>
                                    <Img fluid={data.step3.childImageSharp.fluid} loading="lazy" />
                                </StepImage>
                                <StepLabel id="values">
                                    03 <em>values</em>
                                </StepLabel>
                                <StepText>
                                    P1K is a human-centered and product-passionate company. Just like we care about the
                                    people benefiting from our products, we care about the people we are working with.
                                </StepText>
                                <StepText>
                                    We strive for excellence. Each person working at P1K is a master of their trade. We
                                    believe our success lies in empowering each team member and unleashing their
                                    potential.
                                </StepText>
                                <StepText>
                                    Our work ethics could be described as passionate professionalism. That means we
                                    believe in what we do and want our projects to succeed.
                                </StepText>

                                <StepText>
                                    Empathy guides the way we communicate and collaborate at P1K. We treat each person
                                    with dignity and respect, and we oppose any forms of discrimination. Our community
                                    includes people of various backgrounds and interests, and we welcome them all.
                                    Beyond developing inspiring products, we simply enjoy hanging around together.
                                </StepText>
                                <StepQuote style={{ marginTop: 150 }}>
                                    P1K's internal world is built around an idea that work should be fun, meaningful,
                                    and to the point.
                                </StepQuote>
                            </StepBody>
                        </Step>
                        <Step color="light">
                            <StepBody>
                                <StepImage>
                                    <Img fluid={data.step4.childImageSharp.fluid} loading="lazy" />
                                </StepImage>
                                <StepLabel id="karma">
                                    04
                                    <em>karma</em>
                                </StepLabel>
                                <StepText>
                                    We often get asked about how this all is related to karma. The reference to the
                                    concept of karma in our name reflects our deepest belief that AI technology should
                                    amplify good deeds. The first phase of every project should be to check whether the
                                    idea actually has a meaning and carries something valuable and good to the world.
                                </StepText>
                                <StepText>
                                    P1K brings this attitude to create cutting-edge innovations that optimize modern-day
                                    processes for businesses and people around the world.
                                </StepText>
                            </StepBody>
                        </Step>
                    </StepsList>
                </StepsBody>
            </Steps>
        </>
    );
}

export const imgQuery = graphql`
    query {
        heroSky: file(relativePath: { eq: "mission/hero/sky.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500, srcSetBreakpoints: [2500], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroClouds: file(relativePath: { eq: "mission/hero/clouds.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500, srcSetBreakpoints: [2500], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroMountains: file(relativePath: { eq: "mission/hero/mountains.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500, srcSetBreakpoints: [768, 2500], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        heroCity: file(relativePath: { eq: "mission/hero/city.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500, srcSetBreakpoints: [768, 2500], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        heroPeople: file(relativePath: { eq: "mission/hero/people.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500, srcSetBreakpoints: [768, 2500], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroDrone: file(relativePath: { eq: "mission/hero/drone.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500, srcSetBreakpoints: [768, 2500], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        divider1: file(relativePath: { eq: "mission/div-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [768, 2732], quality: 95) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        divider2: file(relativePath: { eq: "mission/divider-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [768, 2732], quality: 95) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        step3: file(relativePath: { eq: "mission/step-3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 696, srcSetBreakpoints: [696], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        step4: file(relativePath: { eq: "mission/step-4.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 696, srcSetBreakpoints: [696], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
